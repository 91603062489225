<template>
  <div class="pa-0 ma-0">
    <v-container class="mx-0 px-0" v-if="getCart!=null"
                 :class="{wrapper: cartItems.length === 0, cart: cartItems.length !== 0}">
      <v-dialog v-model="showEnterGiftDialog" v-if="userIsLoggedIn" max-width="500">
        <EnterGiftCodeComponent @done="giftCodeApplied" @close="closeGiftCodeDialog"></EnterGiftCodeComponent>
      </v-dialog>
      <v-row align="right">
        <v-col cols="12" :md="userIsLoggedIn ? 8 : 12" class="mt-0 pt-0">
          <v-card class="elevation-25 ma-0 pa-0">
            <v-card-title class="pt-4">
              <h3 class="card-title pmd-title-separator">{{ $t('$vuetify.CartComponent.title') }}</h3>
            </v-card-title>
            <v-card-text>
              <v-card class="elevation-0" :loading="loading && showLoading">
                <v-card-text class="elevation-25 ma-0 pa-0">
                  <v-data-table :items="itemsArray" hide-default-footer :headers="headers">

                    <template v-slot:item.item.title="{item}">
                      <v-icon class="green--text">fas fa-heartbeat</v-icon>
                      {{ $vuetify.rtl ? item.item.title : item.item.en_title }}

                    </template>

                    <template v-slot:item.price="{item}">
                      {{ withCurrency(item.price, getCart.currency) }}
                    </template>

                    <template v-slot:item.final_price="{item}">
                      {{ withCurrency(item.qty * item.price, getCart.currency) }}
                    </template>

                    <template v-slot:item.qty="{item}">
                      <v-icon @click="addToCart(item)" small>mdi-plus</v-icon>
                      <span class="mx-2 font-weight-bold">{{ item.qty }}</span>
                      <v-icon @click="removeItem(item)" small>mdi-minus</v-icon>
                    </template>

                    <template v-slot:item.action="{item}">
                      <span class="mx-2 font-weight-bold">
                        <v-btn x-small text class="red white--text"
                               @click="destroyItem(item)">{{ $t('$vuetify.CartComponent.headersTable[4]') }}</v-btn>
                      </span>
                    </template>
                  </v-data-table>

                  <!--                  <v-data-table
                                        dense
                                        :headers="invoiceHeaders"
                                        :items="invoiceItems"
                                        hide-default-footer
                                        mobile-breakpoint="300"
                                    >
                                      <template v-slot:item="{item}">
                                        <tr v-if="item.show">

                                          <td class="text-left"
                                              :class="{'font-weight-bold final-price-item':item.final}">
                                            {{item.title}}
                                          </td>

                                          <td :class="{'font-weight-bold final-price-item':item.final}">
                                          </td>
                                          <td :class="{'font-weight-bold final-price-item':item.final}">
                                          </td>
                                          <td class="text-center" :class="{'font-weight-bold final-price-item':item.final}">

                                            {{item.price | withCurrency(getCart.currency)}}
                                          </td>
                                        </tr>
                                      </template>

                                    </v-data-table>-->

                </v-card-text>
                <v-row dense justify="center">
                  <v-col cols="12" class="text-center">
                    <!--<v-data-table-->
                    <!--dense-->
                    <!--:headers="invoiceHeaders"-->
                    <!--:items="invoiceItems"-->
                    <!--hide-default-footer-->
                    <!--mobile-breakpoint="300"-->
                    <!--&gt;-->
                    <!--<template v-slot:item="{item}">-->
                    <!--<tr v-if="item.show">-->
                    <!--<td>-->
                    <!--</td>-->
                    <!--<td class="text-left">-->
                    <!--{{item.title}}-->
                    <!--</td>-->
                    <!--<td>-->
                    <!--</td>-->

                    <!--<td class="text-center">-->

                    <!--{{item.price | withCurrency(getCart.currency)}}-->
                    <!--</td>-->
                    <!--</tr>-->
                    <!--</template>-->

                    <!--</v-data-table>-->
                  </v-col>
                </v-row>
                <!--                <v-card-text>-->
                <!--                  <v-row dense justify="center" align="center">-->
                <!--                    <v-col cols="12" sm="5" md="5">-->
                <!--                      <v-text-field-->
                <!--                          @click:clear="voucherCleared"-->
                <!--                          v-if="discount && userIsLoggedIn"-->
                <!--                          outlined-->
                <!--                          clearable-->
                <!--                          rounded-->
                <!--                          v-model="voucherCode"-->
                <!--                          label="کد تخفیف"-->
                <!--                          :messages="validVoucher !== 'unknown' ? message:''"-->
                <!--                          :error="validVoucher==='invalid'"-->
                <!--                          :success="validVoucher==='valid'"-->
                <!--                          class="left-input"-->
                <!--                      >-->
                <!--                        &lt;!&ndash;<template v-slot:append>&ndash;&gt;-->
                <!--                        &lt;!&ndash;<v-btn class="mb-3 primary elevation-0" @click="validateVoucher" rounded>اعمال&ndash;&gt;-->
                <!--                        &lt;!&ndash;</v-btn>&ndash;&gt;-->
                <!--                        &lt;!&ndash;</template>&ndash;&gt;-->
                <!--                        <template v-slot:prepend>-->
                <!--                          <v-tooltip-->
                <!--                              bottom-->
                <!--                          >-->
                <!--                            <template v-slot:activator="{ on }">-->
                <!--                              <v-icon v-on="on">-->
                <!--                                mdi-help-circle-outline-->
                <!--                              </v-icon>-->
                <!--                            </template>-->

                <!--                          </v-tooltip>-->
                <!--                        </template>-->
                <!--                        <template v-slot:append>-->
                <!--                          <v-btn class="primary elevation-0" @click="validateVoucher" rounded-->
                <!--                                 style="top:-7px;"-->
                <!--                          >اعمال-->
                <!--                          </v-btn>-->
                <!--                        </template>-->
                <!--                      </v-text-field>-->

                <!--                    </v-col>-->
                <!--                    <v-col cols="12" v-if="userIsLoggedIn" dense class="text-center pa-0 ma-0">-->
                <!--                      <v-btn small class="" text @click="showEnterGiftDialog=true" >-->
                <!--                        کد هدیه دارید؟ اینجا کلیک کنید-->
                <!--                      </v-btn>-->
                <!--                    </v-col>-->
                <!--                  </v-row>-->

                <!--                  <v-row justify="center" align="center" dense>-->
                <!--                    <v-col cols="12" v-if="userIsLoggedIn ">-->
                <!--                      <template v-if="canUseWallet">-->
                <!--                        <div>-->
                <!--                          <div style="display: inline-block" class="">-->
                <!--                            {{ walletMessage }}-->
                <!--                            مبلغ :-->
                <!--                            {{-->
                <!--                              absNum(differencialPrice) |-->
                <!--                                  withCurrency(getCart.currency)-->
                <!--                            }}-->
                <!--                          </div>-->
                <!--                          <div style="display: inline-block" class="">-->
                <!--                            <v-switch v-model="applyMonetaryWallet" inset-->
                <!--                                      :readonly="isInDebt"-->
                <!--                                      class="mx-2 "></v-switch>-->
                <!--                          </div>-->
                <!--                          <div style="display: inline-block" class="">-->

                <!--                          </div>-->
                <!--                        </div>-->
                <!--                      </template>-->
                <!--                    </v-col>-->

                <!--                  </v-row>-->

                <!--                </v-card-text>-->
              </v-card>


              <v-row v-if="!userIsLoggedIn">
                <v-col cols="12">
                  <v-alert dense type="info">{{ $t('$vuetify.Notify.CartComponent.needLogin') }}</v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-3 elevation-25 pa-0">
            <v-card-title class="pt-4">
              <h3 class="card-title pmd-title-separator">{{ $t('$vuetify.CartComponent.itemsSuggest.title') }}</h3>
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" v-for="(pack,i) in popularPackages" :key="i">
                  <v-card
                      hover
                      outlined
                      :style="packageStyle(pack)"
                      justify="right"
                      class="elevation-10 ma-2"
                      :class="{'elevation-5':pack.id==69,'elevation-3':pack.id != 69}"
                      v-ripple
                  >
                    <div class="text-center py-1" :class="{'packages-top-title':pack.id!=68}">
                      <div class="red--text font-weight-bold text-center" v-if="pack.id == 69">
                        {{ $t('$vuetify.CartComponent.itemsSuggest.maxBuy') }}
                      </div>
                      <div class=" text-center" v-if="pack.id == 68" style="color:white!important;">
                        nospace
                      </div>
                      <div class="red--text font-weight-bold text-center" v-if="pack.id == 70">
                        {{ $t('$vuetify.CartComponent.itemsSuggest.bestBuy') }}
                      </div>
                    </div>
                    <v-card-subtitle class="">{{ $vuetify.rtl ? pack.title : pack.en_title }}</v-card-subtitle>
                    <v-card-title class="  gray--text">
                      <template v-if="pack.currency != 'dollar'">
                        <span class="mx-1">{{ (Number(pack.price).toLocaleString()) }}</span>
                        <span>{{ $t(resolveCurrency(pack.currency)) }}</span>
                      </template>

                      <template v-else>
                        <span class="ma-1">{{ pack.price }}</span>
                        <span>{{ $t(resolveCurrency(pack.currency)) }}</span>
                      </template>


                    </v-card-title>


                    <!--                                        <div v-if="pack.id == 68" class="mt-8"></div>-->


                    <!--<v-card-title class="justify-center">-->
                    <!--<img class="m-auto" src="../../assets/origami.svg"/>-->
                    <!--</v-card-title>-->
                    <v-card-actions class="justify-center py-5">
                      <v-btn rounded block class="px-3 elevation-0"
                             color="primary"
                             outlined
                             :id="`package-${pack.id}`"
                             @click="replacePackage(pack)"
                      >
                        {{ $t('$vuetify.CartComponent.itemsSuggest.action') }}
                      </v-btn>
                    </v-card-actions>

                  </v-card>

                </v-col>

              </v-row>
            </v-card-text>


          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pt-0" v-if="userIsLoggedIn">
          <v-card>
            <v-card-text>
              <v-data-table
                  :headers="smallInvoiceHeaders"
                  :items="invoiceItems"
                  hide-default-footer
                  mobile-breakpoint="300"
              >
                <template v-slot:item="{item}">
                  <tr v-if="item.show">
                    <td
                        :class="checkLang == 'fa' ? 'text-right' : 'text-left'"
                    >
                      {{ item.title }}
                    </td>
                    <td class="text-center"
                        :class="item.class"
                        :style="$vuetify.rtl ? 'direction: rtl;' :''"
                    >

                      {{ withCurrency(item.price, getCart.currency) }}
                    </td>
                  </tr>
                </template>

              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-row dense>
                <v-dialog v-model="showOfflinePaymentDialog" max-width="800" scrollable
                          v-if="userIsLoggedIn"
                >
                  <ClientOfflinePayment @done="savingOfflineDone"
                                        :preorder="preorder"
                                        :cart="getCart"
                                        :show="showOfflinePaymentDialog"
                                        @close="showOfflinePaymentDialog=false"
                                        :coupon_code="voucherCode"></ClientOfflinePayment>
                </v-dialog>

                <v-col cols="12" v-if="userIsLoggedIn" class="text-right">
                  <!--<v-checkbox v-model="privacyPolicy" style="float:right" class="text-center">-->
                  <!--<template v-slot:label>-->
                  <!--<div>-->
                  <!--<a-->
                  <!--target="_blank"-->
                  <!--style="text-decoration: none;"-->
                  <!--href="https://simiaroom.com/%D8%B4%D8%B1%D8%A7%DB%8C%D8%B7-%D8%B6%D9%88%D8%A7%D8%A8%D8%B7-%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87/"-->
                  <!--@click.stop-->
                  <!--&gt;-->
                  <!--شرایط و ضوابط-->
                  <!--</a>-->
                  <!--را خوانده و میپذیرم.-->
                  <!--</div>-->
                  <!--</template>-->
                  <!--</v-checkbox>-->
                  <v-card class="elevation-0" dense>
                    <v-card-text>
                      <v-row dense justify="center" align="center">
                        <v-col cols="12">
                          <v-form @submit.prevent="validateVoucher">
                            <v-text-field
                                @click:clear="voucherCleared"
                                v-if="discount && userIsLoggedIn"
                                dense
                                outlined
                                clearable
                                rounded
                                v-model="voucherCode"
                                :label="$t('$vuetify.CartComponent.discountCode.title')"
                                :messages="validVoucher !== 'unknown' ? message:''"
                                :error="validVoucher==='invalid'"
                                :success="validVoucher==='valid'"
                                class="left-input"
                            >
                              <!--<template v-slot:append>-->
                              <!--<v-btn class="mb-3 primary elevation-0" @click="validateVoucher" rounded>اعمال-->
                              <!--</v-btn>-->
                              <!--</template>-->

                              <template v-slot:append>
                                <v-btn class=" elevation-0" @click="validateVoucher" rounded
                                       outlined
                                       style="top:-3px;" :style="checkLang == 'fa' ? 'left:-20px;' :'right:-20px;'"
                                >{{ $t('$vuetify.CartComponent.discountCode.action') }}
                                </v-btn>
                              </template>
                            </v-text-field>
                          </v-form>

                        </v-col>

                      </v-row>

                      <v-row justify="center" align="center" dense>
                        <v-col cols="12" v-if="userIsLoggedIn ">
                          <template v-if="canUseWallet">
                            <div>
                              <div style="display: inline-block" class="">
                                <div>{{ walletMessage }}</div>
                              </div>

                              <div style="display: inline-block" class="">
                                <v-switch v-model="applyMonetaryWallet" inset
                                          :readonly="isInDebt"
                                          class="mx-2 "></v-switch>
                              </div>
                              <div style="display: inline-block" class="">
                                : {{ $t('$vuetify.CartComponent.price') }}
                                {{
                                  withCurrency(absNum(differencialPrice), getCart.currency)
                                }}
                              </div>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="12" v-if="userIsLoggedIn" dense class="text-center pa-0 ma-0">
                          <v-btn small class="" text @click="showEnterGiftDialog=true">
                            {{ $t('$vuetify.CartComponent.haveGiftCode') }}
                          </v-btn>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                  <template>
                    <div style="clear: both" class="text-center">
                      <img class="gateways-logo" :src="require('../../assets/croppedvisa.png')" alt="">
                      <v-btn class="elevation-25 darken-1"
                             :class="paymentButtonCurrencyClass"
                             height="50"
                             :id="payBtnId"
                             :disabled="loading"
                             color="green white--text"
                             block
                             @click="sendOrder" style="position:relative;">
                        <template v-if="getCart.currency == 'dollar'">
                          {{ $t('$vuetify.preOrderPage.payDollar') }}
                          <div class="mx-2" style="font-weight: bolder"> ({{
                              withCurrency(finalPrice, getCart.currency)
                            }})
                          </div>
                        </template>
                        <template v-else-if="getCart.currency == 'rial'">
                          {{ $t('$vuetify.preOrderPage.payRial') }}
                        </template>
                        <template v-else>
                          {{ $t('$vuetify.preOrderPage.pay') }}
                        </template>
                        <!--                        <v-avatar size="28" class="absolute-payment-img">-->
                        <!--                          <img :src="paymentMethodURL" alt="">-->
                        <!--                        </v-avatar>-->
                      </v-btn>
                      <div></div>

                      <div class="fixed-payment" v-if="$vuetify.breakpoint.smAndDown">
                        <v-btn class="elevation-0 darken-1"
                               height="50"
                               :id="payBtnId"
                               :disabled="loading"
                               color="green white--text"
                               block
                               :class="paymentButtonCurrencyClass"
                               @click="sendOrder" style="position:relative;">
                          <template v-if="getCart.currency == 'dollar'">
                            <span
                                style="font-weight: bolder;font-size: 1.2em;">{{
                                $t('$vuetify.preOrderPage.payDollar')
                              }}</span>
                            <div class="mx-2"> ({{ withCurrency(finalPrice, getCart.currency) }})</div>
                            <!--                            <span class="mx-1" style="text-transform: none">(Credit Card / Debit Card)</span>-->
                          </template>
                          <template v-else-if="getCart.currency == 'rial'">
                            <span style="font-weight: bolder;font-size: 1.2em;">
                            {{ $t('$vuetify.preOrderPage.payRial') }}
                            </span>
                          </template>
                          <template v-else>
                            {{ $t('$vuetify.preOrderPage.pay') }}
                          </template>
                          <!--                                              <v-avatar size="28" class="absolute-payment-img">-->
                          <!--                                                <img :src="paymentMethodURL" alt="">-->
                          <!--                                              </v-avatar>-->
                        </v-btn>
                        <!--                        <div class="payment-image">-->
                        <!--                          <img class="" :src="require('../../assets/croppedvisa.png')" alt="">-->
                        <!--                        </div>-->
                      </div>

                      <v-btn rounded block class="elevation-25 outlined-warning mt-2 ml-2" small
                             @click="showOfflinePayment">
                        {{$t('$vuetify.factorsPage.PaymentType[2]')}}
                      </v-btn>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";
import ClientOfflinePayment from '@/components/clients/ClientOfflinePayment';
import EnterGiftCodeComponent from '@/components/EnterGiftCodeComponent'
import {resolveCurrency} from "@/utilities";

export default {
  name: 'clientCart',
  components: {
    ClientOfflinePayment,
    EnterGiftCodeComponent
  },
  props: ['discount', 'price', 'remove', 'preorder', 'preorderCoupon', 'showLoading'],

  data() {
    return {
      popularPackages: [],
      payBtnId: null,

      showEnterGiftDialog: false,
      headers: [
        {text: this.$t('$vuetify.CartComponent.headersTable[0]'), value: 'item.title', sortable: false},
        {text: this.$t('$vuetify.CartComponent.headersTable[1]'), value: 'price', sortable: false},
        {text: this.$t('$vuetify.CartComponent.headersTable[2]'), value: 'qty', sortable: false},
        {text: this.$t('$vuetify.CartComponent.headersTable[3]'), value: 'final_price', sortable: false},
        {text: '', value: 'action', sortable: false},
      ],
      // invoiceItems: [],
      smallInvoiceHeaders: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          text: this.$t('$vuetify.CartComponent.price'),
          align: 'center',
          sortable: false,
          value: 'price',
        }
      ],
      invoiceHeaders: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'title',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'title',
        },
        {
          text: 'مبلغ',
          align: 'center',
          sortable: false,
          value: 'price',
        },

      ],
      applyMonetaryWallet: true,
      privacyPolicy: true,
      sendingOrder: false,
      showOfflinePaymentDialog: false,
      requesting: false,
      voucherCode: "",
      validVoucher: 'unknown',
      message: "",
      voucher: {},
      // loading: false,
      wallet: null,
      items: [
        {
          text: 'داشبورد',
          disabled: false,
          href: '/fa/panel/dashboard/',
        },
        {
          text: 'پیش فاکتور',
          disabled: true,
          href: 'order',
        }
      ]
    }
  },
  beforeDestroy() {
    EventBus.$off('sendOrderEvent', this.sendOrder);
  },
  mounted() {
    this.getPopularPackages();
    if (this.preorderCoupon != null) {
      this.voucherCode = this.preorderCoupon;
      if (this.userIsLoggedIn) {
        this.validateVoucher();
      }
    }
    // if (this.preorderCoupon != null) {
    //     this.voucherCode = this.preorderCoupon;
    //     this.validateVoucher();
    // }
  },
  created() {
    if (!this.preorder) {
      this.$store.dispatch('getCart');
    }

    if (this.userIsLoggedIn) {
      let client = this.$store.getters.getUserInfo;
      this.wallet = client.affiliate_wallet;
    }


    if (this.getCart != null) {
      if (this.getCart.couponCode != null) {
        this.voucherCode = this.getCart.couponCode;
        this.validateVoucher();
      }
    }


    EventBus.$on('sendOrderEvent', this.sendOrder);
    EventBus.$on('userLoggedIn', this.reinitializeWallet);

  },
  computed: {
    checkLang() {
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    },
    paymentButtonCurrencyClass() {
      if (this.getCart != null) {
        return this.getCart.currency == 'rial' ? 'payment_button_currency_rial' : 'payment_button_currency_dollar';
      }
      return '';

    },
    isInDebt() {
      if (this.wallet != null) {
        return this.wallet[this.getCart.currency].balance < 0;
      } else {
        return false;
      }
    },
    walletMessage() {
      let message = '';

      if (this.wallet != null) {
        if (this.wallet[this.getCart.currency].balance < 0) {
          message = this.$t('$vuetify.CartComponent.clearingWallet');
        } else {
          message = this.$t('$vuetify.CartComponent.payByWallet');
        }
      }

      return message;
    },
    paymentMethodURL() {
      return this.getCart.currency === 'dollar' ?
          require('../../assets/images/paypal-icon.png') :
          require('../../assets/images/zarinpal-icon.png');

    },
    itemsArray() {
      let data = [];
      let keys = Object.keys(this.cartItems);
      keys.forEach(key => {
        data.push(this.cartItems[key])
      });

      return data;

    },
    canUseWallet() {
      // console.log(this.userIsLoggedIn);
      // console.log(this.wallet);
      return this.userIsLoggedIn && this.wallet != null && this.wallet[this.getCart.currency].balance != 0
    },
    invoiceItems() {
      return [
        {class: '', title: this.$t('$vuetify.CartComponent.invoiceItems.total'), price: this.totalPrice, show: true},
        {
          class: '',
          title: this.walletMessage,
          price: this.absNum(this.differencialPrice),
          show: this.applyMonetaryWallet && this.canUseWallet
        },
        {
          class: 'green--text',
          style: 'direction:rtl;',
          title: this.$t('$vuetify.CartComponent.invoiceItems.offer'),
          price: -this.getCart.sumDiscount,
          show: this.getCart.sumDiscount != null && this.getCart.sumDiscount > 0,
        },
        {
          class: 'font-weight-bold final-price-item',
          title: this.$t('$vuetify.CartComponent.invoiceItems.finalPrice'),
          price: this.finalPrice,
          show: true,
          final: true
        },
      ]
    },
    differencialPrice() {
      return this.getCart.finalPrice - this.finalPriceWithWallet;
    },
    finalPriceWithWallet() {

      if (this.wallet != null) {
        if (this.getCart.currency == 'dollar') {
          if (this.wallet.dollar.balance > 0) {
            if (this.wallet.dollar.balance >= this.getCart.finalPrice) {
              return 0;
            } else {
              let amount = this.getCart.finalPrice - this.wallet.dollar.balance;
              return amount.toFixed(2);
            }
          } else if (this.wallet.dollar.balance < 0) {
            let amount = this.getCart.finalPrice - this.wallet.dollar.balance;
            return amount.toFixed(2);
          } else {
            return this.getCart.finalPrice;
          }
        } else {

          if (this.wallet.rial.balance > 0) {
            if (this.wallet.rial.balance >= this.getCart.finalPrice) {
              return 0;
            } else {
              let amount = this.getCart.finalPrice - this.wallet.rial.balance;
              return amount.toFixed(0);
            }
          } else if (this.wallet.rial.balance < 0) {
            let amount = this.getCart.finalPrice - this.wallet.rial.balance;
            return amount.toFixed(2);
          } else {
            return this.getCart.finalPrice;
          }
        }

      }


    },
    ...mapGetters(['getCart', 'loading', 'userIsLoggedIn']),
    cartItems() {
      return this.$store.getters.getCartItems;
    },
    calculateSum() {
      this.$store.dispatch('calculateSum', this.cartItems);
      return this.$store.getters.getSumPrice;
    },
    totalPrice() {
      if (this.getCart.currency == 'dollar') {
        return this.getCart.totalPrice.toFixed(2);
      } else {
        return this.getCart.totalPrice.toFixed(0);
      }
    },
    finalPrice() {
      if (this.applyMonetaryWallet && this.canUseWallet) {
        return this.finalPriceWithWallet;
      } else {
        if (this.getCart.currency == 'dollar') {
          return this.getCart.finalPrice.toFixed(2);
        } else {
          return this.getCart.finalPrice.toFixed(0);
        }
      }
    }
  },
  methods: {
    withCurrency(val, currency) {
      if (currency == 'dollar') {
        return this.$vuetify.rtl  ?  (` ${Number(val).toFixed(2)} ` + this.$t('$vuetify.currency.dollar')) : this.$t('$vuetify.currency.dollar')  + `${Number(val).toFixed(2)} ` ;
      } else {
        let x = Number(val).toFixed(0);
        return Number(x).toLocaleString() + this.$t('$vuetify.currency.rial');
      }
    },
    resolveCurrency(input) {
      return resolveCurrency(input);
    },
    packageStyle(pack) {
      return `border-radius:6px;cursor:default;border-width: 1px;border-color: ${pack.id == 69 ? '#34316E' : 'white'};`;
    },
    replacePackage(pack) {
      EventBus.$emit('setLoading', true);
      // this.voucherCleared(false);
      // this.voucherCode = null;
      this.$store.dispatch('emptyCart')
          .then(() => {
            this.addToCart({
              item: pack
            });
          }).catch(() => {
        EventBus.$emit('setLoading', false);
      }).finally(() => {
        if (this.voucherCode != null && this.voucherCode != undefined && this.voucherCode != "") {
          this.validateVoucher(false);
        }
      })
    },
    getPopularPackages() {
      this.$store.dispatch('searchProducts', {
        page: 1, payload: {ids: '68|69|70'}
      }).then((data) => {
        this.popularPackages = data.packages;
        console.log(data)
      })
    },
    giftCodeApplied() {
      this.closeGiftCodeDialog();
      this.reinitializeWallet();
    },
    closeGiftCodeDialog() {
      this.showEnterGiftDialog = false;
    },
    sendWebengageAddToCart(data) {
      window.webengage.track('add_to_cart', data);
    },
    absNum(input) {
      return Math.abs(input);
    },
    voucherCleared(loading = true) {
      this.validVoucher = 'unknown';
      this.message = '';
      if (loading) {
        EventBus.$emit('setLoading', true);
      }

      this.$store.dispatch('validateCoupon', {
        'couponCode': ''
      }).finally(() => {
        if (loading) {
          EventBus.$emit('setLoading', false);
        }
      });
    },
    reinitializeWallet() {
      let client = this.$store.getters.getUserInfo;
      this.wallet = client.affiliate_wallet;
    },
    showOfflinePayment() {
      if (this.privacyPolicy) {
        this.showOfflinePaymentDialog = true;
      } else {
        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.acceptTerms'));
      }
    },
    savingOfflineDone() {
      this.showOfflinePaymentDialog = false;
      let message = this.$t('$vuetify.CartComponent.giftCode.acceptCode');
      if (this.preorder) {
        message = this.$t('$vuetify.Notify.successfullyWithRedirect');

        setTimeout(() => {
          this.$router.push({name: 'dashboard', params: {lang: 'fa'}});
        }, 2000)
      }

      EventBus.$emit('notify', 'green', undefined, message, 'ga_offline_payment');
    },
    addToCart(item) {
      // this.loading = true;
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('addToCart', {id: item.item.id}).then(() => {
        // this.loading = false;
        let product = {
          'name': item.item.title,
          'id': item.item.id,
          'price': item.price,
          'brand': 'Simiaroom',
          'category': 'Counseling',
          'quantity': 1
        };

        this.sendWebengageAddToCart(product);

        this.$gtm.trackEvent({
          'event': 'eec.addToCart',
          'ecommerce': {
            'add': {
              'products': [{
                'name': item.item.title,
                'id': item.item.id,
                'price': item.price,
                'brand': 'Simiaroom',
                'category': 'Counseling',
                // 'variant': 'Gray',
                'quantity': 1
              }]
            }
          }
        });

      }).finally(() => {
        // this.loading = false;
        EventBus.$emit('setLoading', false);
      })
    },
    destroyItem(item) {
      // this.loading = true;
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('cartRemoveItemAll', {
        package_id: item.item.id

      }).then(() => {
        // this.loading = false;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });
    },
    removeItem(item) {
      // this.loading = true;
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('cartRemoveItem', {
        package_id: item.item.id

      }).then(() => {
        // this.loading = false;
        this.$gtm.trackEvent({
          'event': 'eec.removeFromCart',
          'ecommerce': {
            'remove': {                               // 'remove' actionFieldObject measures.
              'products': [{
                'name': item.item.title,
                'id': item.item.id,
                'price': item.price,
                'brand': 'Simiaroom',
                'category': 'Counseling',
                // 'variant': 'Gray',
                'quantity': 1
              }]
            }

          }
        });
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });
    },
    validateVoucher(loading = true) {

      if (this.voucherCode == "" && this.voucherCode == null || !this.voucherCode.length) {
        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.CartComponent.enterCode'));
        return;
      }
      // let code = event.keyCode;
      // console.log(code)
      // if ((code >= 48 && code <= 57) || (code >= 65 && code <= 90) || (code >= 96 && code <= 105) || (code == 109) || (code == 189)) {

      // clearTimeout(this.voucherTimer);

      // this.voucherTimer = setTimeout(() => {
      this.requesting = true;
      if (loading) EventBus.$emit('setLoading', true);
      this.$store.dispatch('validateCoupon', {
        'couponCode': this.voucherCode
      }).then(resp => {
        if (resp.data.status == 'invalid') {
          this.validVoucher = 'invalid';
          this.message = this.$t('$vuetify.CartComponent.discountCode.errorCode');
          window.webengage.track('promotion_code_failure', {
            code: this.voucherCode
          });
        } else if (resp.data.status == 'valid') {
          this.message = this.$t('$vuetify.CartComponent.discountCode.acceptCode');
          this.validVoucher = 'valid';
          this.voucher = resp.data;
          window.webengage.track('promotion_code_success', {
            code: this.voucherCode
          });
        }
      }).finally(() => {
        this.requesting = false;
        if (loading) EventBus.$emit('setLoading', false);
      });
      // }, 500);
      // } else {
      //     console.log('Invalid Btn');
      // }
    },
    voucherPrice: function () {
      let amount = this.$store.getters.getSumPrice;
      let percent = this.voucher.percent / 100;
      let value = this.voucher.value;
      let price = (percent * amount) + value;
      return price.toFixed(2);
    },
    allData() {
      const cart = this.$store.getters.getCartItems;

      let products = [];
      let data = {};
      cart.forEach((item) => {
        products.push(
            {
              'id': item._id,
              'quantity': item.qty
            }
        );
      });

      data.products = products;
      data.voucher = this.voucherCode;
      data.totalPrice = this.$store.getters.getSumPrice;
      data.finalPrice = this.finalPrice;
      console.log('data', data);
      return data;
    },
    sendOrder() {

      let products = [];
      Object.keys(this.getCart.items).forEach((key) => {
        let item = this.getCart.items[key];
        products.push({
          'name': item.item.title,
          'id': item.item.id,
          'price': item.price,
          'brand': 'Simiaroom',
          'category': 'Counseling',
          // 'variant': 'Gray',
          'quantity': item.qty
        })
      })


      if (this.privacyPolicy) {

        // this.loading = true;
        EventBus.$emit('setLoading', true);
        this.payBtnId = 'ga_payment_button';
        this.$store.dispatch('sendOrder', {
          couponCode: this.voucherCode,
          applyWallet: this.applyMonetaryWallet
        }).then((data) => {
          this.$gtm.trackEvent({
            'event': 'eec.checkout',
            'ecommerce': {
              'checkout': {
                'actionField': {'step': 1, 'option': 'Submit'},
                products
              }
            }
            // ,
            // 'eventCallback': function () {
            //
            // }
          });

          window.webengage.track('proceed_to_payment');
          this.$nextTick().then(() => {
            window.location = data.data.url;
          });

          // this.loading = false;
        }).catch(() => {
          // this.loading = false;
          this.$emit('finishedSendingOrder');
          EventBus.$emit('setLoading', false);
        }).finally(() => {

          // this.loading = false;
        })

      } else {
        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.acceptTerms'));
      }
    }
  },
  watch: {
    getCart() {
      if (this.getCart != null) {
        if (this.getCart.couponCode != null) {
          this.voucherCode = this.getCart.couponCode;
        }
      }
    },
    preorderCoupon() {
      this.voucherCode = this.preorderCoupon;
      this.validateVoucher();
      // if (this.userIsLoggedIn) {
      //     this.voucherCode = this.preorderCoupon;
      //     this.validateVoucher();
      // }
    },
    userIsLoggedIn(newVal) {
      if (this.preorderCoupon != null && newVal) {
        this.validateVoucher();
      }
    },
    // voucherCode() {
    //     if (this.userIsLoggedIn && this.voucherCode.length == 0) {
    //         this.validateVoucher();
    //     }
    // }
  },
  filters: {

    toDollar(val) {
      return `$ ${Number(val).toFixed(2)}`;
    },
    toRial(val) {
      return ` تومان ${Number(val).toFixed(0)}`;
    },


  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-cart-img {
  background-size: cover;
  background-position: center;
  height: 40vh;
}

.cart {
  display: flex;
  flex-direction: column;
}

.final-price-item {
  border-top: .5px solid rgba(0, 0, 0, .4);
}

/*.left-input >>> input {*/
/*text-align: left*/
/*}*/


.fixed-payment {
  box-shadow: 0 -3px 10px rgba(0, 0, 0, .2);
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 10;
  width: 100%;
  padding: 12px 5% 12px 5%;
  background-color: white;
  text-align: center;
}

.payment-image img {
  width: 100%;
}

.payment-image {
  width: 110px;
  margin-right: auto;
  text-align: left !important;
  opacity: .7;
  left: 25px;
  bottom: 0;
  margin-top: 5px;
}


.fixed-payment-bg {

  /*width: 100%;*/
  /*height: 100%;*/
  /*position: absolute;*/
  /*left: 0;*/
  /*top: 0;*/
  /*-webkit-filter: blur(2px);*/
  /*-moz-filter: blur(2px);*/
  /*-o-filter: blur(2px);*/
  /*-ms-filter: blur(2px);*/
  /*filter: blur(2px);*/
  /*background-color: white;*/
}

.gateways-logo {
  width: 50%;
}

.packages-top-title {
  color: green;
  background-color: rgba(0, 0, 0, .1);
  box-shadow: 0 10px 7px -7px rgba(0, 100, 100, .08);
}
</style>